import React from 'react'
import Typography from '@material-ui/core/Typography'

import useStyles from './styles'

const TypoNunitoSans = ({children, className, ...props}) => {
  const overrideClasses = useStyles()

  return (
    <Typography classes={overrideClasses} className={className} {...props}>
      {children}
    </Typography>
  )
}

export default TypoNunitoSans