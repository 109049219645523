import 'fonts/NunitoSans.css'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  body2: {
    ...theme.typography.body2,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  caption: {
    ...theme.typography.caption,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  button: {
    ...theme.typography.button,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h1: {
    ...theme.typography.h1,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h2: {
    ...theme.typography.h2,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h3: {
    ...theme.typography.h3,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h4: {
    ...theme.typography.h4,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h5: {
    ...theme.typography.h5,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  h6: {
    ...theme.typography.h6,
    fontSize: '1rem',
    fontWeight: '300',
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  subtitle2: {
    ...theme.typography.subtitle2,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
  overline: {
    ...theme.typography.overline,
    fontFamily: 'NunitoSans, Helvetica, Arial, sans-serif',
  },
}))

export default useStyles
