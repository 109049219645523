import {BrowserRouter} from 'react-router-dom'
import {MuiThemeProvider} from '@material-ui/core/styles'
import React, {useEffect} from 'react'

import defaultTheme from 'theme'
import Routes from 'components/Routes'

const App = () => {
  useEffect(() => {
    document.body.style.backgroundColor =
      defaultTheme.palette.background.default
    document.body.style.margin = 0;
  })

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </MuiThemeProvider>
  )
}

export default App
