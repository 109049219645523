import React from 'react'

import TypoMain from  'components/TypoMain'

import useStyles from './styles'

const Header = () => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <TypoMain className={classes.appName}>upleeft</TypoMain>
    </header>
  )
}

export default Header
