import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",      
  },
})

export default useStyles
