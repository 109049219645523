import React from 'react'

import TypoNunitoSans from 'components/TypoNunitoSans'

const TypoMain = ({children, className, ...props}) => {

  return (
    <TypoNunitoSans className={className} {...props}>
      {children}
    </TypoNunitoSans>
  )
}

export default TypoMain
