import {createMuiTheme} from '@material-ui/core/styles'

const defaultTheme = createMuiTheme({
  palette: {
    background: {
      default: '#f8f8f8',
      secondary: '#922c88',
      light: '#af67a4',
      dark: '#303030',
      card: '#ffffff',
      border: '#f8f8f8',
    },
    primary: {
      main: '#922c88',
      dark: '#303030',
    },
    text: {
      primary: '#303030',
      secondary: '#922c88',
      light: '#d4aecf',
      grey: '#787878',
      white: '#FFFFFF',
    },
  },
  chartsPalette: [
    '#922c88',
  ],
  layout: {
    header: {
      height: '80px',
    },
  },
  overrides: {
    
  },
})

export default defaultTheme
