import React from 'react'

import Header from 'components/Layout/Header'
import useStyles from './styles'

const Layout = ({children}) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <Header/>
      {children}
    </div>
  )
}

export default Layout
