import * as moment from 'moment'
import * as R from 'ramda'

import {useParams} from 'react-router-dom'
import { ResponsiveLine } from '@nivo/line'
import {useTheme} from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import {Helmet} from "react-helmet";
import React, { useState, useEffect } from "react"

import {
  formatEvolutionForChart,
  getMatchConfig,
  getMatchEvolution,
  populateComments,
} from 'helpers/matchHelper'
import Layout from 'components/Layout'
import TypoMain from  'components/TypoMain'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const Match = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useIsMobile()
  
  const [match, setMatch] = useState(null)
  const [evolution, setEvolution] = useState(null)
  const [config, setConfig] = useState(null)
  const [comments, setComments] = useState([])

  const {id: matchId} = useParams()

  useEffect(() => {
    async function fetchData() {
        const url = `https://upleeft-api-dev.herokuapp.com/v1/match/${matchId}`
        const res = await fetch(url)
        res
          .json()
          .then(res => {
            setMatch(res)
            setConfig(getMatchConfig(res.period))
            setEvolution(getMatchEvolution(res.team_a, res.team_b, res.scores))
            setComments(populateComments(res))
          })
          .catch()
      }

    fetchData()
    setInterval(fetchData, 30000); // runs every 30 seconds.

  }, [matchId, classes])

  return (
    <Layout>
      {match && config && (
      <>
        <Helmet>
          <title>{`${match.team_a} - ${match.team_b}, ${match.description}`}</title>
          <meta name="description" content={`Score en direct, propulsé par upleeft.com`}/>
          <meta name="og:site_name" content="Upleeft"/>
          <meta name="og:title" content={`${match.team_a} - ${match.team_b}, ${match.description}`}/>
          <meta name="og:description" content={`Score en direct, propulsé par Upleeft`}/>
        </Helmet>
        <div>
          <div className={classes.scoreBoard}>
            <TypoMain className={classes.matchDescription}>{match.sport.label} - {match.description}</TypoMain>
            <div className={classes.scoreBlock}>
              <TypoMain 
                className={`${classes.teamName} ${(config.isUnderway) ? classes.teamNameIsUnderway : classes.teamNameHasEnded}`}>
                  {match.team_a}
              </TypoMain>
              <div className={classes.scores}>
                <TypoMain 
                  className={`${classes.score} ${(config.isUnderway) ? classes.scoreIsUnderway : classes.scoreHasEnded}`}>
                    {match.lastScore.score_a}
                </TypoMain>
                <div className={classes.separatorBlock}>
                  <TypoMain className={classes.separator}>-</TypoMain>
                </div>
                <TypoMain 
                  className={`${classes.score} ${(config.isUnderway) ? classes.scoreIsUnderway : classes.scoreHasEnded}`}>
                    {match.lastScore.score_b}
                </TypoMain>
              </div>
              <TypoMain 
                className={`${classes.teamName} ${(config.isUnderway) ? classes.teamNameIsUnderway : classes.teamNameHasEnded}`}>
                  {match.team_b}
              </TypoMain>
            </div>
            <TypoMain className={classes.matchPeriod}>{config.periodLabel}</TypoMain>
          </div>
          <div className={classes.matchDetails}>
            <Grid container spacing={4} direction="row" alignItems="flex-start" justify="center">
              <Grid item xs={12} md={8}>
                <Card elevation={0} className={classes.card}>
                  <CardContent className={classes.commentList}>
                    <CardHeader
                      title={
                        <TypoMain variant="h6" component="h2">
                            Commentaires en direct
                        </TypoMain>
                      } />
                      {comments.map((c, index) => {
                        return (
                          <div className={classes.comment} key={index}>
                            <TypoMain className={classes.commentMoment} >{c.moment}</TypoMain>
                            {c.title && (
                              <TypoMain className={classes.commentTitle} >{c.title}</TypoMain>
                            )}
                            <TypoMain className={classes.commentContent} >{c.content}</TypoMain>
                          </div>
                        )
                      })}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card elevation={0} className={`${classes.coloredCard} ${classes.userCard}`}>
                  <CardContent className={classes.userInfo}>
                      <AccountCircleIcon className={classes.userInfoIcon}/>
                      <TypoMain className={classes.userInfoLabel}>
                          <span>{match.user.name}</span>&nbsp;{`(${match.team.label})`}
                      </TypoMain>
                  </CardContent>
                </Card>
                {evolution && (
                  <Card elevation={0} className={classes.card}>
                    <CardContent>
                      <CardHeader
                        title={
                          <TypoMain variant="h6" component="h2">
                              Evolution du score
                          </TypoMain>
                        } />
                        {match.sport.hasChart && (
                          <div className={classes.evolutionChart}>
                                <ResponsiveLine
                                  data={formatEvolutionForChart(evolution)}
                                  margin={{top: 25, right: 25, bottom: 10, left: 25}}
                                  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                  colors={theme.chartsPalette}
                                  pointSize={7}
                                  pointBorderWidth={1}
                                  enablePoints={!isMobile}
                                  pointBorderColor={{ from: 'serieColor' }}
                                  pointColor={theme.palette.background.card}
                                  gridYValues={[0]}
                                  enableGridX={false}
                                  enableArea
                                  areaOpacity={0.1}
                                  crosshairType='x'
                                  axisLeft={null}
                                  axisBottom={null}
                                  lineWidth={2}
                                  useMesh
                                  tooltip={e => {
                                    return (
                                      <div className={classes.chartTooltip} id={e.id}>
                                        <TypoMain className={classes.chartTooltipLabel}>
                                          {e.point.data.score
                                              ? `${e.point.data.score}`
                                              : "Aucune donnée"}
                                        </TypoMain> 
                                      </div>
                                      )
                                    }}
                              />
                          </div>
                        )}
                        <div className={classes.evolutionList}>
                          {R.reverse(evolution).map((score, index) => {
                            if(index < evolution.length - 1) {
                              return (
                                <div className={classes.evolutionScoreWrapper} key={index}>
                                  <TypoMain 
                                    className={classes.evolutionDate}>
                                      {moment(score.scoreDate).format("HH:mm")}
                                  </TypoMain>
                                  <div className={classes.evolutionScoreContent}>
                                    <TypoMain 
                                      className={classes.evolutionTeam}>
                                        {score.team_a}
                                    </TypoMain>
                                    <TypoMain 
                                      className={classes.evolutionScore}>
                                       {score.score_a}
                                    </TypoMain>
                                    <TypoMain 
                                      className={classes.evolutionSeparator}>
                                        -
                                    </TypoMain>
                                    <TypoMain 
                                      className={classes.evolutionScore}>
                                         {score.score_b}
                                    </TypoMain>
                                    <TypoMain 
                                      className={classes.evolutionTeam}>
                                        {score.team_b}
                                    </TypoMain>
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    )}
  </Layout>
  )
}

export default Match
