import React from 'react'

const Home = () => {

  return (
    <div>
      <p>Bientôt disponible - jonathan@upleeft.com</p>
    </div>
  )
}

export default Home
