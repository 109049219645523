import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  card: {
    backgroundColor: palette.background.card,
    border: `1px solid ${palette.background.border}`,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.0392157) 0px 1px 15px 0px, rgba(0, 0, 0, 0.0392157) 0px 1px 6px 0px',
    '& + div': {
      marginTop: spacing(2),
    }
  },
  coloredCard: {
    backgroundColor: palette.background.secondary,
    color: palette.text.white,
    border: 'none',
    borderRadius: '8px',
  },
  evolutionChart: {
    width: '100%',
    height: '150px',
    marginBottom: spacing(4),
  },
  matchDescription: {
    fontSize: '0.875rem',
    fontWeight: '200',
    padding: `${spacing(2)}px 0 ${spacing(1)}px 0`,
    color: palette.text.grey,
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  matchDetails: {
    margin: spacing(4),
    display: 'flex',
    justifyContent: 'flex-start',
  },
  scoreBoard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.background.card,
    boxShadow: 'rgba(0, 0, 0, 0.0392157) 0px 1px 15px 0px',
  },
  matchPeriod: {
    fontSize: '0.875rem',
    fontWeight: '400',
    padding: `${spacing(1)}px 0 ${spacing(2)}px 0`,
    color: palette.text.primary,
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  separator: {
    fontSize: '1.25rem',
    color: palette.text.primary,
  },
  separatorBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${spacing(4)}px`,
    [breakpoints.down('sm')]: {
      padding: `0 ${spacing(2)}px`,
    },
  },
  scoreBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  teamName: {
    display: 'flex',
    flex: 1, 
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    fontWeight: '300',
    [breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
    '&:first-of-type': {
      justifyContent: 'flex-end',
    },
  },
  teamNameIsUnderway: {
    color: palette.text.primary,
  },
  teamNameHasEnded: {
    color: palette.text.grey,
  },
  score: {
    fontSize: '1.625rem',
    fontWeight: '500',
    [breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  scores: {
    display: 'flex',
    margin: `0 ${spacing(5)}px`,
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      margin: `0 ${spacing(2)}px`,
    },
  },
  scoreIsUnderway: {
    color: palette.text.secondary,
  },
  scoreHasEnded: {
    color: palette.text.grey,
  },
  chartTooltip: {
    border: `1px solid ${palette.background.border}`,
    backgroundColor: palette.background.card,
    padding: '8px 16px',
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.0392157) 0px 1px 15px 0px, rgba(0, 0, 0, 0.0392157) 0px 1px 6px 0px',
  },
  chartTooltipLabel: {
    color: palette.text.primary,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px !important',
  },
  userCard: {
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  userInfoIcon: {
    width: '30px',
    height: '30px',
    marginRight: spacing(2),
  },
  userInfoLabel: {
    fontSize: '0.875rem',
    fontWeight: 300,
    '& > span': {
      fontWeight: 400,
    }
  },
  comment: {
    padding: `${spacing(2)}px ${spacing(4)}px`,
  },
  commentContent: {
    fontSize: '1rem',
    fontWeight: '300',
  },
  commentMoment: {
    fontSize: '0.75rem',
    fontWeight: '200',
    color: palette.text.grey,
  },
  commentTitle: {
    fontSize: '0.875rem',
    fontWeight: '500',
  },
  evolutionList: {
    padding: `0 ${spacing(2)}px`,
  },
  evolutionScoreWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  evolutionScoreContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  evolutionScore: {
    fontSize: '0.875rem',
    margin: `0 ${spacing(2)}px`,
    fontWeight: '600',
  },
  evolutionTeam: {
    fontSize: '0.875rem',
    fontWeight: '200',
    textTransform: 'uppercase',
  },
  evolutionDate: {
    fontSize: '0.875rem',
    color: palette.text.light,
  },
})) 

export default useStyles
