import {Redirect, Route, Switch} from 'react-router-dom'
import React from 'react'

import Home from 'pages/Home'
import Match from 'pages/Match'
import NotFound from 'pages/NotFound'

const routes = [
  {
    path: '/match/:id',
    component: Match,
    layout: true,
  },
  {
    exact: true,
    path: '/',
    pageName: 'Upleeft | Scores en direct',
    component: Home,
    layout: false,
  },
  {
    path: '*',
    layout: false,
    render: () => <Redirect to="/404" />,
  },
]

const Routes = () => {
  return (
    <Switch>
      {routes.map(route => <Route {...route} key={route.path} />)}
    </Switch>
  )
}

export default Routes
