import * as moment from 'moment'

export const getMatchConfig = (period, classes) => {
    switch(period) {
        case -1:
        return {
            periodLabel: 'Terminé',
            isUnderway: false,
        }
        case 0:
        return {
            periodLabel: 'Non commencé',
            isUnderway: false,
        }
        case 1:
        return {
            periodLabel: 'En cours',
            isUnderway: true,
        }
        default: 
        return {
            periodLabel: 'Non commencé',
            isUnderway: false,
        }
    }
}

export const getMatchEvolution = (team_a, team_b, scores) => {
    const sanitizedScores = getSanitizedScores(team_a, team_b, scores)
    return buildMatchEvolution(sanitizedScores)
}

const getSanitizedScores = (team_a, team_b, scores, currentIndex = 0, sanitizedScores = []) => {
    if (currentIndex === scores.length) return sanitizedScores

    if (currentIndex === 0) {
        const lastScore = scores[scores.length - 1]
        return getSanitizedScores(team_a, team_b, scores.reverse(), currentIndex + 1, [{team_a, team_b, scoreDate: lastScore.scoreDate, score_a: lastScore.score_a, score_b: lastScore.score_b}])
    } else {
        const currentScore = scores[currentIndex]
        const previousScore = sanitizedScores[0]

        const sanitizedScore = {
            team_a,
            team_b,
            scoreDate: currentScore.scoreDate,
            score_a: (currentScore.score_a < previousScore.score_a) 
                ? currentScore.score_a 
                : previousScore.score_a,
            score_b: (currentScore.score_b < previousScore.score_b) 
                ? currentScore.score_b 
                : previousScore.score_b,
        }

        const isNewScore = (sanitizedScore.score_a !== previousScore.score_a || 
            sanitizedScore.score_b !== previousScore.score_b)

        return (isNewScore) 
            ? getSanitizedScores(team_a, team_b, scores, currentIndex + 1, [sanitizedScore, ...sanitizedScores])
            : getSanitizedScores(team_a, team_b, scores, currentIndex + 1, [...sanitizedScores])
    }
}

const buildMatchEvolution = (scores, currentIndex = 0, evolutions = []) => {
    if (currentIndex === scores.length) return evolutions

    const evolution = {
        ...scores[currentIndex],
        diff: scores[currentIndex].score_a - scores[currentIndex].score_b
    }
    return buildMatchEvolution(scores, currentIndex + 1, [...evolutions, evolution])
}

export const formatEvolutionForChart = (data) => {
    return [
        {
          "id": "score-evolution",
          "data": data.map((e, index) => ({
              x:  index,
              score: `${e.score_a} - ${e.score_b}`,
              y: e.diff,
          }))
        }
      ]
}

export const populateComments = (match) => {
    if (!match || !match.startDate) return []
    return [{
        moment: moment(match.startDate).format("hh:mm"),
        title: 'C\'est parti !',
        content: `Début de la rencontre `,
    }]
}