import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({layout, palette, spacing}) => ({
  appName: {
    fontSize: '1.75rem',
    fontWeight: '500',
  },
  header: {
    display: "flex",
    flexDirection: "row",      
    justifyContent: 'center',
    alignItems: 'center',
    height: layout.header.height,
    color: palette.text.primary,
    backgroundColor: palette.background.card,
    padding: `${spacing(1)}px ${spacing(4)}px`,
  },
}))

export default useStyles
